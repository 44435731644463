import React from 'react';

import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import 'chartjs-adapter-moment';


class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onSelectedUnitChange(e.target.value);
  }
  

  render() {    
    const graphData = {
      datasets: [
        {
          label: '',
          lineTension: 0,
          backgroundColor: 'transparent',
          borderColor: this.props.color,
          borderWidth: 4,
          pointBackgroundColor: this.props.color,
          data: this.props.data,
        },
      ],
    };

    const options = {
      maintainAspectRatio: false,
      plugins: {
        legend: false
      },
      scales: {
        x: {
            scaleLabel: {
                display: true,
                labelString: 'Time'
                },
                type: 'time',
                time: {
                  parser: 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ',
                  unit: 'second',
                  stepSize: 5,
                  displayFormats: {
                 'second': 'HH:mm:ss'
                 }
               },
          },
      },
      animation: {
        duration: 0
      }
    };

    const graphClassName = this.props.multiChart ? 'h-48' : 'h-96'

    return (
      <div>
        <div className="grid grid-flow-row auto-rows-max">
        <h2 className="text-2xl m-2 font-bold ">{this.props.title}</h2>
            <select onChange={this.handleChange} 
                    className="absolute right-2  form-select block w-50 px-2 py-2 text-xl font-normal 
                         text-gray-700 bg-white bg-clip-padding bg-no-repeat border 
                         border-solid border-gray-300 rounded transition ease-in-out
                         m-2 focus:text-gray-700 focus:bg-white focus:border-blue-600 
                         focus:outline-none" aria-label="Default select example">
              {this.props.units.map((item) => (<option key={item.label} value={item.label} >{item.label}</option>))}
            </select>
        </div>
        <div className={graphClassName}>
            <Chart
            type="line"
            data={graphData}
            options={options}
            id="ch1"
            />
        </div>
        
      </div>
    );

  }
}

export default Graph;