import React from 'react';
import "./App2.css";
import Header from './Header';
import Graph from './Graph';
import RawData from './RawData';
import Info from './Info';
import axios from 'axios'

//const url = 'http://172.17.0.1:8080';
const url = 'https://jkh0cy33ai.execute-api.ap-northeast-1.amazonaws.com';
const headers = {headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application / x-www-form-urlencoded',
             }};
const updateFrequency = 2000;


const selectDeviceItem = [
                      {
                        label: "Select a Device",
                        value: ""
                      },
                    ]

const defaultUnit = [{
  label: "AD Converter Value",
  value: {'args': 'x',
          'func': 'return x;'}}
]

class App2 extends React.Component {
    constructor(props) {
      super(props);
      
  
      // This binding is necessary to make `this` work in the callback
      //this.testClicked = this.testClicked.bind(this);
      this.autoUpdateClicked = this.autoUpdateClicked.bind(this);
      this.stopUpdateClicked = this.stopUpdateClicked.bind(this);
      this.graphClicked = this.graphClicked.bind(this);
      this.rawDataClicked = this.rawDataClicked.bind(this);
      this.infoClicked = this.infoClicked.bind(this);
      this.handleSelectedDeviceChange = this.handleSelectedDeviceChange.bind(this);
      this.handleSelectedUnitChange0 = this.handleSelectedUnitChange0.bind(this);
      this.handleSelectedUnitChange1 = this.handleSelectedUnitChange1.bind(this);
      this.handleSelectedUnitChange2 = this.handleSelectedUnitChange2.bind(this);
      this.handleSelectedUnitChange3 = this.handleSelectedUnitChange3.bind(this);

      this.state = {
        updateOn: true, 
        showGraph: true,
        showRawData: false, 
        showInfo: false,
        devices: selectDeviceItem,
        units: defaultUnit,
        device: '',
        func0: new Function('x', 'return x;'),
        func1: new Function('x', 'return x;'),
        func2: new Function('x', 'return x;'),
        func3: new Function('x', 'return x;'),
        data0: [],
        data1: [],
        data2: [],
        data3: [],
      };


      this.getUnitList();
      this.getDeviceList();      
      
    }

    componentDidMount() {
      this.timerID = setInterval(
        () => this.updateDeviceData(),
        updateFrequency
      );
    }
    
    getUnitList() {
      axios.get(url + "/unitlist", headers)
        .then(response => {
          
          let unitlist = response.data.map(function( x ) {

          return {label: x['title'], args: x['args'], func: x['func']};
        });
        this.setState({
          units: unitlist
        });

      }).catch(err => {
        alert("Failed to connect to Data Base.");
        console.log("err:", err);
      });
    }

    getDeviceList() {

      axios.get(url + "/devicelist", headers)
        .then(response => {
          
         let devicelist = response.data.map(function( x ) {
          return {label: x['Id'], value: x['Id']};
        });
        this.setState({
          devices: selectDeviceItem.concat(devicelist)
        });

    }).catch(err => {
      alert("Failed to connect to Data Base.");
      console.log("err:", err);
    });
      
    }

    updateDeviceData() {

      if(this.state.updateOn === false || this.state.device === '')
      {
        return;
      }

      var endunixtime = new Date().getTime() + 20000;
      var startunixtime = endunixtime - 80000;
      
      axios.get(url + "/storage?id=" + this.state.device + "&startunixtime=" + startunixtime + "&endunixtime=" + endunixtime, headers)
                .then(response => {
                  
                  const copyItems0 = [];
                  const copyItems1 = [];
                  const copyItems2 = [];
                  const copyItems3 = [];
                  response.data.map((item) => {
                    if(item['ch'] === '0')
                    {
                      copyItems0.push({x:item['x'], y: item['y']});
                    }
                    if(item['ch'] === '1')
                    {
                      copyItems1.push({x:item['x'], y: item['y']});
                    }
                    if(item['ch'] === '2')
                    {
                      copyItems2.push({x:item['x'], y: item['y']});
                    }
                    if(item['ch'] === '3')
                    {
                      copyItems3.push({x:item['x'], y: item['y']});
                    }
                  });
            
                  this.setState({
                    data0: copyItems0,
                    data1: copyItems1,
                    data2: copyItems2,
                    data3: copyItems3,
                  });

                }).catch(err => {
                  console.log("err:", err);
                });
    }

    autoUpdateClicked() {
      this.setState({
        updateOn: true,
      });
    }

    stopUpdateClicked() {
      this.setState({
        updateOn: false,
      });
    }
  
    graphClicked() {
      
        this.setState({
          showGraph: true,
          showRawData: false,
          showInfo: false
        });
      
    }

    rawDataClicked() {
      
      this.setState({
        showGraph: false,
        showRawData: true,
        showInfo: false,
      });
    }

    infoClicked() {
      
        this.setState({
          showGraph: false,
          showRawData: false,
          showInfo: true,
        });
    }

    handleSelectedDeviceChange(device) {
      console.log("App2: " + device);
      this.setState({device});
    }

    handleSelectedUnitChange0(label) {
      let index = this.state.units.findIndex((x) => {return x.label === label})
      this.setState({func0: new Function(this.state.units[index].args, this.state.units[index].func)});
    }
    handleSelectedUnitChange1(label) {
      let index = this.state.units.findIndex((x) => {return x.label === label})
      this.setState({func1: new Function(this.state.units[index].args, this.state.units[index].func)});
    }
    handleSelectedUnitChange2(label) {
      let index = this.state.units.findIndex((x) => {return x.label === label})
      this.setState({func2: new Function(this.state.units[index].args, this.state.units[index].func)});
    }
    handleSelectedUnitChange3(label) {
      let index = this.state.units.findIndex((x) => {return x.label === label})
      this.setState({func3: new Function(this.state.units[index].args, this.state.units[index].func)});
    }
    
    render() {

      const stopUpdateClass = "bg-transparent text-gray-700 py-2 px-4 border border-gray-500 rounded";
      const autoUpdateClass = "bg-gray-500 text-white font-bold py-2 px-4 border border-gray-700 rounded";

      const btnNotSelectedClass = "bg-transparent text-blue-700 py-2 px-4 border border-blue-500 rounded";
      const btnSelectedClass = "bg-blue-500 text-white font-bold py-2 px-4 border border-blue-700 rounded";

      const calcData0 = [];
      const calcData1 = [];
      const calcData2 = [];
      const calcData3 = [];
      this.state.data0.map((item) => {
        calcData0.push({x: item['x'], y: this.state.func0(item['y'])});
      });
      this.state.data1.map((item) => {
        calcData1.push({x: item['x'], y: this.state.func1(item['y'])});
      });
      this.state.data2.map((item) => {
        calcData2.push({x: item['x'], y: this.state.func2(item['y'])});
      });
      this.state.data3.map((item) => {
        calcData3.push({x: item['x'], y: this.state.func3(item['y'])});
      });

      const multiChart = (calcData1.length > 0 ||
                          calcData2.length > 0 ||
                          calcData3.length > 0 )

      return (
        <>
          <Header devices={this.state.devices} onSelectedDeviceChange={this.handleSelectedDeviceChange} />
          <div className="float-right mt-1 mr-2">
            <button onClick={this.autoUpdateClicked} className={this.state.updateOn ? autoUpdateClass : stopUpdateClass}>
              Auto Update
            </button>
            <button onClick={this.stopUpdateClicked} className={this.state.updateOn ? stopUpdateClass: autoUpdateClass}>
              Stop Update
            </button>
          </div>
          <div className="inline-flex mt-1 ml-2">
            <button onClick={this.graphClicked} className={this.state.showGraph ? btnSelectedClass : btnNotSelectedClass}>
              Graph
            </button>
            <button onClick={this.rawDataClicked} className={this.state.showRawData ? btnSelectedClass : btnNotSelectedClass}>
              Raw Data
            </button>
            <button onClick={this.infoClicked} className={this.state.showInfo ? btnSelectedClass : btnNotSelectedClass}>
              Info
            </button>
          </div>
          {this.state.showGraph ?
          <div>
           <Graph title={"CH0"} color={'#007bff'} data={calcData0} units={this.state.units} multiChart={multiChart} onSelectedUnitChange={this.handleSelectedUnitChange0} id={"ch0"} /> 
           {calcData1.length > 0 ?
           <Graph title={"CH1"} color={'#ff7b00'} data={calcData1} units={this.state.units} multiChart={multiChart} onSelectedUnitChange={this.handleSelectedUnitChange1} id={"ch1"} /> : <div />
           }
           {calcData2.length > 0 ?
           <Graph title={"CH2"} color={'#00ff7b'} data={calcData2} units={this.state.units} multiChart={multiChart} onSelectedUnitChange={this.handleSelectedUnitChange2} id={"ch2"} /> : <div />
           }
           {calcData3.length > 0 ?
           <Graph title={"CH3"} color={'#ff7bff'} data={calcData3} units={this.state.units} multiChart={multiChart} onSelectedUnitChange={this.handleSelectedUnitChange3} id={"ch3"} /> : <div />
           }
          </div>
          : <div />
          
          }
          {this.state.showRawData ?
          <RawData data0={this.state.data0} data1={this.state.data1} data2={this.state.data2} data3={this.state.data3} /> : <div />
          }
          {this.state.showInfo ?
          <Info /> : <div />
          }
        </>
      );
    }
  }

  export default App2;