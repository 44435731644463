import React from 'react';

class Header extends React.Component {
    constructor(props) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
    }
  
    handleChange(e) {
      console.log(e.target.value);
      this.props.onSelectedDeviceChange(e.target.value);
    }
  
    render() {   
      
      return (
        <>
          <div className="flex space-x-2  bg-black">
              <h1 className="flex-auto w-40 text-2xl m-1 font-normal text-gray-100">
                SUCS DEMO
              </h1>
              <select onChange={this.handleChange} 
                      className="flex-auto w-60 data-te-select-init">
                {this.props.devices.map((item) => (<option key={item.label} value={item.value}
                                                   className="py-2"
                                                    >{item.label}</option>))}
              </select>
              
            </div>
        </>
      );
  
    }
  }
  
  export default Header; 