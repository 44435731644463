import React from 'react';
import qrcode from "./qrcode.png";

class Info extends React.Component {
    constructor(props) {
      super(props);
    }
  
    render() {
      return (
        <div>
          <h1 className="text-3xl m-2 font-bold">Info</h1>
          <h2 className="text-xl m-2">https://sucs-demo.org</h2>
          <img src={qrcode} height="300" width="200"></img>
        </div>
      );
    }
  }


  export default Info;