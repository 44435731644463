import React from 'react';


class RawData extends React.Component {
    constructor(props) {
      super(props);
    }
  
    render() {

      return (
        <div>
          <h1 className="text-3xl m-2 font-bold">Raw Data</h1>
          <table className="table-auto m-2">
                <thead className="bg-white border-b">
                    <tr className="bg-gray-300">
                        <th className="border px-4 py-2">CH</th>
                        <th className="border px-4 py-2">DateTime</th>
                        <th className="border px-4 py-2">Value</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.data0.map((item) =>
                        <tr className="bg-gray-100" key={item.x}>
                            <td className="border px-4 py-2">0</td>
                            <td className="border px-4 py-2">{item.x}</td>
                            <td className="border px-4 py-2">{item.y}</td>
                        </tr>
                    )}
                    {this.props.data1.map((item) =>
                        <tr className="bg-gray-100" key={item.x}>
                            <td className="border px-4 py-2">1</td>
                            <td className="border px-4 py-2">{item.x}</td>
                            <td className="border px-4 py-2">{item.y}</td>
                        </tr>
                    )}
                    {this.props.data2.map((item) =>
                        <tr className="bg-gray-100" key={item.x}>
                            <td className="border px-4 py-2">2</td>
                            <td className="border px-4 py-2">{item.x}</td>
                            <td className="border px-4 py-2">{item.y}</td>
                        </tr>
                    )}
                    {this.props.data3.map((item) =>
                        <tr className="bg-gray-100" key={item.x}>
                            <td className="border px-4 py-2">3</td>
                            <td className="border px-4 py-2">{item.x}</td>
                            <td className="border px-4 py-2">{item.y}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
      );
    }
  }

export default RawData; 